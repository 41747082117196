.student {
  background-image: url('../images/cardtemplate1.png');
}
.medewerker, .employee {
  background-image: url('../images/cardtemplate2.png');
}

.cardpreview {
  position:          relative;
  background-size:   contain;
  background-repeat: no-repeat;
  width:             350px;
  height:            221px;

  * {
    display: none;
  }

  .name {
    display: block;
    position: absolute;
    top: 120px;
    left: 125px;
    line-height: 12px;
    color: #777;
    font-size: 18px;
    font-weight: bold;

    .title, .firstname, .nameoncard {
      display: none;
    }

    .initials, .middlename, .lastname {
      display: inline;
    }

    .initials::after, .middlename::after {
      content: ' ';
    }
  }

  .barcode2 {
    display: block;
    position: absolute;
    top: 150px;
    left: 125px;
    width: 150px;
    //line-height: 25px;

    .barcodediv {
      display: block;
      height: 25px;

      svg {
        display: block;
        width: 100%;
        height: inherit;

        g {
          display: block;
          width: 100%;
          height: inherit;

          rect {
            display: inherit;
          }
        }
      }
    }

    .valuediv {
      display: block;
      color: #777;
      height: 15px;
      line-height: 15px;
      font-size: 10px;
      letter-spacing: 3px;
      text-align: center;
    }

    z-index: 999;
  }

  .activatedon {
    display: block;
    position: absolute;
    top: 205px;
    left: 125px;
    font-size: 9px;
    color: #777;
  }

  .activatedon::before {
    content: 'Uitgifte ';
  }

  .cardnumber {
    display: block;
    position: absolute;
    top: 205px;
    left: 220px;
    font-size: 9px;
    color: #777;
  }

  .cardnumber::before {
    content: 'Kaartnr. ';
  }

  .photo {
    display: block;
    position: absolute;
    border: 1px solid #C3C3C3;
    width: 90px;
    height: 12px;
    top: 38px;
    left: 16px;
  }


}
