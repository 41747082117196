.w3-theme-l5 {color:#000 !important; background-color:#f3f3f3 !important}
.w3-theme-l4 {color:#663366 !important; background-color:#ECE5EC !important}
.w3-theme-l3 {color:#663366 !important; background-color:#D9CCD9 !important}
.w3-theme-l2 {color:#fff !important; background-color:#209aff !important}
.w3-theme-l1 {color:#fff !important; background-color:#0075d4 !important}
.w3-theme-d1 {color:#fff !important; background-color:#00447c !important}
.w3-theme-d2 {color:#fff !important; background-color:#003d6e !important}
.w3-theme-d3 {color:#fff !important; background-color:#003560 !important}
.w3-theme-d4 {color:#fff !important; background-color:#002d53 !important}
.w3-theme-d5 {color:#fff !important; background-color:#002645 !important}

.w3-theme-dark {color:#000 !important; background-color:#663366 !important}
.w3-theme-light {color:#fff !important; background-color:#663366 !important}
.w3-theme-action {color:#fff !important; background-color:#002645 !important}

.w3-theme {color:#fff !important; background-color:#004d8a !important}
.w3-text-theme {color:#004d8a !important}
.w3-border-theme {border-color:#402040 !important}

.w3-hover-theme {color:#663366 !important;}

.w3-hover-theme:hover {color:#8C668C !important; background-color:#ECE5EC !important}
.w3-hover-text-theme:hover {color:#004d8a !important}
.w3-hover-border-theme:hover {border-color:#004d8a !important}

.w3-input-theme,
.w3-input-theme:-webkit-autofill,
.w3-input-theme:-webkit-autofill:hover,
.w3-input-theme:-webkit-autofill:focus,
.w3-input-theme:-webkit-autofill:active { color:#663366 !important; background-color:#fefefe !important; border-color: #663366 !important }

.w3-input-theme:focus { outline: none; border: 2px solid #663366 !important; }
.w3-input-theme:checked { color:#004d8a !important; background-color:#004d8a !important; }

.w3-button-theme { color:#D9CCD9; font-weight: bold !important; background-color:#663366 !important; border-color: #663366 !important }
.w3-button-theme:focus, .w3-button-theme:active { color:#663366 !important; background-color:#D9CCD9 !important; border-color: #9F809F !important; outline-color: #663366 !important; }
.w3-button-theme:hover { color:#663366 !important; background-color:#D9CCD9 !important; border-color: #663366 !important; }

.w3-button:disabled, .w3-button-theme[disabled]:hover { color:#D9CCD9 !important; background-color:#663366 !important; border-color: #663366 !important }

.w3-check-theme:focus, w3-check-theme.active { outline-color: #0075d4 !important; }

.w3-button-theme { white-space: pre-line; }
