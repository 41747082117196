$app-size-width:   100vw;
$app-size-height:  100vh;

$page-width-wide:  600px;
$page-width-small: 95vw;

$header-height:    70px;
$footer-height:    60px;

$content-min-height: calc( #{$app-size-height} - #{$header-height} - #{$footer-height} - 68px );

$button-width-wide:   calc( ( #{$page-width-wide}/2  ) - 58px  );
$button-width-small:  100%;

$photo-max-width:    calc( #{$page-width-wide} - 74px         );
$photo-max-height:   calc( #{$content-min-height} - 298px     );
