.digitalcard {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#digitalcard {
  #logocontainer {
    .logo {
      height: 50px;
      width: auto;
    }
  }

  #photocontainer {
    .photo {
      width: 200px;
      height: auto;
    }
  }

  #datacontainer {
    * {
      display: none;
    }

    .name {
      display: block;

      * {
        display: none;
      }

      .nameoncard {
        display: block;
      }
    }

    .group {
      display: block;
    }
  }

  #barcodecontainer {
    display: block;

    #barcode {
      display: inline-block;
    }
  }

  #qrcodecontainer {
    display: block;

    #qrcode {
      display: inline-block;
      width: 200px;
      height: 200px;
    }
  }
}

#showcontainer {
  #showphoto,
  #showqrcode {
    display: inline-block;
    width: 90%;
    max-width: 90%;

    height: auto;
    max-height: 90%;
  }

  /*
  #showbarcode {
    display: inline-block;
    width: 200px;
    height: 100px;
  }

  #showqrcode {
    display: inline-block;
    width: 90%;
    height: 200px;
  }
  */
}
